import React, {createContext, useContext, useEffect, useState} from "react";
import {useTelegram} from "../hooks/useTelegram";
import * as client from "../utils/client";
import axios from "axios";
import * as constants from "../constants/constants";

const JwtContext = createContext();

export const useJwt = () => {
    return useContext(JwtContext);
};

export const axiosInstance = axios.create({
    baseURL: constants.BACKEND_URL + "/api",
    headers: {
        'Content-Type': 'application/json',
    }
});

export const JwtProvider = ({children}) => {
    const {cloudStorage, telegramUser} = useTelegram();
    const [jwtToken, setJwtToken] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        if (!cloudStorage) {
            console.log("cloudStorage is not available.");
            setLoading(false);
            return;
        }

        cloudStorage.getItem("jwt", (err, token) => {
            if (err || !token) {
                console.log("Error getting JWT token:", err);
                setLoading(false);
                return;
            }
            setJwtToken(token);
        });
    }, [cloudStorage]);

    useEffect(() => {
        const authenticateUser = async () => {
            if (jwtToken && telegramUser && !user) {
                try {
                    const userJson = await client.login(jwtToken);
                    setUser(userJson);
                } catch (error) {
                    console.error("Can't authorize user:", error.message);
                    setAuthError("Не удалось авторизовать пользователя. Пожалуйста, попробуйте снова.");
                }
                setLoading(false);
            }
        };

        authenticateUser();
    }, [jwtToken, telegramUser, user]);

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            (config) => {
                if (jwtToken) {
                    config.headers.Authorization = `Bearer ${jwtToken}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // Здесь можно добавить логику выхода пользователя или обновления токена
                    console.warn("Unauthorized access - возможно, нужно выйти из системы.");
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [jwtToken]);

    return (
        <JwtContext.Provider value={{jwtToken, user, loading, authError, setJwtToken, setUser}}>
            {children}
        </JwtContext.Provider>
    );
};
