import React, {useEffect, useState} from 'react';
import './ProblemsPage.css';
import * as client from "../../../utils/client";
import * as constants from "../../../constants/constants";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {Link} from "react-router-dom";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import {isPrivilegeUser} from "../../../utils/userUtil";
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {FaEye, FaFileDownload} from "react-icons/fa";
import LatexRenderer from '../../LatexRenderer/LatexRenderer';

const ProblemsPage = () => {
    const {telegram} = useTelegram();
    const {jwtToken, user} = useJwt();
    const [minComplexity, setMinComplexity] = useState('');
    const [maxComplexity, setMaxComplexity] = useState('');
    const [problems, setProblems] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({key: 'complexity', direction: 'descending'});
    const [filters, setFilters] = useState({theme: 'All', type: 'All'});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProblem, setSelectedProblem] = useState(null);

    const themes = Object.entries(ProblemTheme).map(([key, value]) => ({key, value}));
    const types = Object.entries(ProblemType).map(([key, value]) => ({key, value}));

    useEffect(() => {
        telegram.BackButton.hide();
    }, []);

    useEffect(() => {
        client.getProblems()
            .then((problemsJson) => {
                setProblems(problemsJson);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    const sortedProblems = React.useMemo(() => {
        let sortableProblems = [...problems];

        if (filters.theme !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.theme === filters.theme);
        }
        if (filters.type !== 'All') {
            sortableProblems = sortableProblems.filter(problem => problem.type === filters.type);
        }

        if (searchQuery) {
            sortableProblems = sortableProblems.filter(problem =>
                problem.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (minComplexity !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.complexity >= minComplexity);
        }

        if (maxComplexity !== '') {
            sortableProblems = sortableProblems.filter(problem => problem.complexity <= maxComplexity);
        }

        if (sortConfig !== null) {
            sortableProblems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return sortableProblems;
    }, [problems, sortConfig, filters, searchQuery, minComplexity, maxComplexity]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const fetchReviews = (problemId) => {
        setReviews(problems.filter((problem) => problem.id === problemId)[0].reviews);
        setReviewModalOpen(true);
    };

    if (!user || !isPrivilegeUser(user)) {
        return null;
    }

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div>
            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Поиск по названию задачи..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className='filters'>
                <label>
                    Тема:
                    <select name="theme" value={filters.theme} onChange={handleFilterChange}>
                        <option value="All">Любой</option>
                        {themes.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                </label>

                <label>
                    Тип:
                    <select name="type" value={filters.type} onChange={handleFilterChange}>
                        <option value="All">Любой</option>
                        {types.map(({key, value}) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                </label>

                <label>
                    Сложность от:
                    <input
                        type="number"
                        value={minComplexity}
                        onChange={(e) => setMinComplexity(e.target.value)}
                        placeholder="Минимум"
                    />
                </label>

                <label>
                    Сложность до:
                    <input
                        type="number"
                        value={maxComplexity}
                        onChange={(e) => setMaxComplexity(e.target.value)}
                        placeholder="Максимум"
                    />
                </label>
            </div>

            <div className="table-responsive">
                <table className='problems-table'>
                    <thead>
                    <tr>
                        <th>Имя Задачи</th>
                        <th onClick={() => requestSort('averageRating')} className='sortable'>
                            Рейтинг
                            {sortConfig.key === 'averageRating' ? (
                                sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                            ) : null}
                        </th>
                        <th onClick={() => requestSort('wellKnownCount')} className='sortable'>
                            Баяны
                            {sortConfig.key === 'wellKnownCount' ? (
                                sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'
                            ) : null}
                        </th>
                        <th>Тема</th>
                        <th>Тип</th>
                        <th>Решение</th>
                        <th>Автор</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedProblems.length > 0 ? (
                        sortedProblems.map(problem => (
                            <tr key={problem.id}>
                                <td>
                                    <Link to={`/problem/${problem.id}`} className='problem-link'>
                                        {problem.name}
                                    </Link>
                                </td>
                                <td>{problem.averageRating}</td>
                                <td className="download-cell">
                                    <button onClick={() => fetchReviews(problem.id)}>
                                        {problem.wellKnownCount}
                                    </button>
                                </td>
                                <td>{ProblemTheme[problem.theme] || problem.theme}</td>
                                <td>{ProblemType[problem.type] || problem.type}</td>
                                <td className="download-cell">
                                    <button onClick={() => setSelectedProblem(problem)}><FaEye/></button>
                                </td>
                                <td>{problem.author.username}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className='no-data'>Нет задач, соответствующих фильтрам.</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

            {selectedProblem && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h3>Решение задачи</h3>
                        <LatexRenderer latexCode={selectedProblem.tutorial}/>
                        {selectedProblem.solutionImageUrl
                            ? <div style={{display: "flex"}}>
                                <p>Скачать условие</p>
                                <button
                                    className={'downloadSolutionButton'}
                                    onClick={() =>
                                        telegram.openLink(`${constants.BACKEND_URL}/api/problemFiles/${selectedProblem.id}?jwt=${jwtToken}&fileType=SOLUTION`)}>
                                    <FaFileDownload/>
                                </button>
                            </div>
                            : <p>Для этой задачи нет решения картинкой.</p>
                        }
                        <button className='close-button' onClick={() => setSelectedProblem(null)}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}

            {isReviewModalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <h3>Ревью задачи</h3>
                        {reviews.length > 0 ? (
                            reviews.map((review, index) => (
                                <div key={index} className="review-card">
                                    <p className="review-source">Источник: {review.sourceUrl}</p>
                                    <p className="review-author">Автор ревью: {review.reviewAuthorCredentials}</p>
                                </div>
                            ))
                        ) : (
                            <p>Нет ревью для этой задачи.</p>
                        )}
                        <button className='close-button' onClick={() => setReviewModalOpen(false)}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProblemsPage;
