export const isAdmin = (user) => {
    return user && user.role === "ADMIN";
}

export const isReviewer = (user) => {
    return user && user.role === "REVIEWER";
}

export const isPrivilegeUser = (user) => {
    return isReviewer(user) || isAdmin(user);
}

export const UserStatus = {
    SCHOOLCHILD: "Школьник",
    STUDENT: "Студент",
    PHD_CANDIDATE: "Кандидат наук | PBD",
    DOCTOR_OF_SCIENCE: "Доктор наук"
};