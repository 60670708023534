import React, {useEffect, useState} from 'react';
import './UsersPage.css';
import * as client from "../../../utils/client";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {Link} from "react-router-dom";
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {ProblemType} from "../../../utils/problemUtil";

const UsersPage = () => {
    const {jwtToken, user} = useJwt();
    const {telegram} = useTelegram();
    const [loading, setLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('rating-desc');

    useEffect(() => {
        telegram.BackButton.hide();
    }, [telegram]);

    useEffect(() => {
        client.getProblems()
            .then((problemsJson) => {
                const usersMap = problemsJson.reduce((acc, problem) => {
                    const author = problem.author;
                    if (!acc[author.tgId]) {
                        acc[author.tgId] = {
                            tgId: author.tgId,
                            username: author.username,
                            firstName: author.firstName,
                            lastName: author.lastName,
                            rating: author.rating,
                            problems: []
                        };
                    }
                    acc[author.tgId].problems.push(problem);
                    return acc;
                }, {});

                const usersArray = Object.values(usersMap);
                setUsers(usersArray);
                setLoading(false);
            })
            .catch(err => {
                console.log('Error while fetching problems', err);
                setLoading(false);
            });
    }, [telegram]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSort = (e) => {
        setSortOption(e.target.value);
    };

    const filteredUsers = users
        .filter(user => {
            const fullName = `${user.lastName}, ${user.firstName}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        })
        .sort((a, b) => {
            switch (sortOption) {
                case 'rating-asc':
                    return a.rating - b.rating;
                case 'rating-desc':
                    return b.rating - a.rating;
                case 'problems-asc':
                    return a.problems.length - b.problems.length;
                default:
                    return 0;
            }
        });

    if (loading) {
        return <LoadingSpinner/>;
    }

    return (
        <div>
            <div className='search-bar'>
                <input
                    type="text"
                    placeholder="Поиск по фамилии, имени..."
                    value={searchQuery}
                    onChange={handleSearch}
                    className='search-input'
                />
            </div>

            <div className='filters'>
                <label htmlFor="sort-select">Сортировка:</label>
                <select
                    id="sort-select"
                    value={sortOption}
                    onChange={handleSort}
                >
                    <option value="rating-asc">Рейтинг автора (asc)</option>
                    <option value="rating-desc">Рейтинг автора (desc)</option>
                    <option value="problems-asc">Количество задач (asc)</option>
                </select>
            </div>

            <div className='users-list'>
                {filteredUsers.length > 0
                    ? (filteredUsers.map((user) => (
                        <div key={user.tgId} className='user-card'>
                            <div
                                className='user-header'
                                onClick={() => setSelectedUserId(prevId => prevId === user.tgId ? null : user.tgId)}
                                role="button"
                                aria-expanded={selectedUserId === user.tgId}
                                tabIndex={0}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        setSelectedUserId(prevId => prevId === user.tgId ? null : user.tgId);
                                    }
                                }}
                            >
                                <div className='user-info'>
                                    <span className='username'>{user.firstName} {user.lastName}</span>
                                    <span className='rating'>Rating: {user.rating}</span>
                                </div>
                                <div className={`arrow ${selectedUserId === user.tgId ? 'up' : 'down'}`}>
                                    <i className={`fas fa-chevron-${selectedUserId === user.tgId ? 'up' : 'down'}`}></i>
                                </div>
                            </div>
                            {selectedUserId === user.tgId && (
                                <div className='task-list'>
                                    {user.problems.map((problem) => (
                                        <div key={problem.id} className='task-item'>
                                            <Link to={`/problem/${problem.id}`}>
                                                <span
                                                    className='problem-type'>{ProblemType[problem.type] || problem.type}</span>
                                                <span
                                                    className='problem-rating'>Rating: {problem.averageRating}</span>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )))
                    : (<p className='no-users-message'>No users found.</p>)}
            </div>
        </div>
    );
};

export default UsersPage;
