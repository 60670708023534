import React, {useEffect, useState} from 'react';
import './ProblemPage.css';
import {useNavigate, useParams} from 'react-router-dom';
import * as client from '../../../utils/client';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {useTelegram} from "../../../hooks/useTelegram";
import {useJwt} from "../../../context/JwtContext";
import {ProblemTheme, ProblemType} from "../../../utils/problemUtil";
import LatexRenderer from '../../LatexRenderer/LatexRenderer';
import * as constants from "../../../constants/constants";
import {isAdmin} from "../../../utils/userUtil";

const ProblemPage = () => {
    const {jwtToken, user} = useJwt();
    const {problemId} = useParams();
    const {telegram} = useTelegram();
    const navigate = useNavigate();
    const [problem, setProblem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showReviews, setShowReviews] = useState(false);

    const goBack = () => {
        navigate(-1);
    };

    const deleteProblem = () => {
        client.deleteProblem(problemId)
            .then(() => {
                alert("Задача успешно отозвана.");
                navigate('/');
            })
            .catch(error => {
                console.error("Error deleting problem:", error);
                alert("Ошибка при отзыве задачи.");
            });
    };

    useEffect(() => {
        if (window.history.length > 1) {
            telegram.BackButton.show();
            telegram.BackButton.onClick(goBack);
        } else {
            telegram.BackButton.hide();
        }
        return () => {
            telegram.BackButton.offClick(goBack);
        };
    }, [telegram, navigate]);

    useEffect(() => {
        client.getProblemById(problemId)
            .then(problemJson => {
                setProblem(problemJson);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching problem:", error);
                setLoading(true);
            });
    }, [problemId]);

    if (loading) {
        return <LoadingSpinner/>;
    }

    const toggleReviews = () => {
        setShowReviews(prev => !prev);
    };

    return (
        <div style={{marginBottom: "3rem"}}>
            <div className='problem-details'>
                <div className='problem-page-header'>
                    <p className='problem-theme'>
                        Тема: {ProblemTheme[problem.theme] || problem.theme} |
                        Тип: {ProblemType[problem.type] || problem.type}
                    </p>
                    <p className='problem-theme'>Название: {problem.name}</p>
                    {user.id !== problem.author.id && (
                        <p className='problem-theme'>Рейтинг: {problem.averageRating}</p>
                    )}
                </div>
                {problem.coAuthors && problem.coAuthors.length > 0 && (
                    <div className='problem-coauthors'>
                        <p>Соавторы:</p>
                        {problem.coAuthors.map((coAuthor, index) => (
                            <p key={index} className='coauthor-name'>{coAuthor}</p>
                        ))}
                    </div>
                )}
                <p>Условие:</p>
                <LatexRenderer latexCode={problem.statement}/>
                <p>Условие (картинка):</p>
                {problem.illustrationImageUrl &&
                    <div className='problem-media'>
                        <img
                            src={`${constants.BACKEND_URL}/api/problemFiles/${problem.id}?jwt=${jwtToken}`}
                            alt="Problem Illustration"
                            className='problem-image'
                        />
                    </div>}
                {user.id === problem.author.id && (
                    <button className='withdraw-button' onClick={deleteProblem}>
                        Отозвать задачу
                    </button>
                )}
                {isAdmin(user) && (
                    <div className='problem-reviews'>
                        <button className='withdraw-button' onClick={toggleReviews}>
                            {showReviews ? 'Скрыть все ревью' : 'Показать все ревью'}
                        </button>
                        {showReviews && problem.reviews.length > 0 && (
                            <div className='reviews-list'>
                                {problem.reviews.map((review) => (
                                    <div key={review.id} className='review'>
                                        <p><span>Тэг ревью:</span> {review.tag}</p>
                                        <p><span>Комментарий:</span> <LatexRenderer latexCode={review.text}/></p>
                                        <p><span>Красота:</span> {review.beauty}</p>
                                        <p><span>Сложность:</span> {review.complexity}</p>
                                        <p>
                                            <span>Баян:</span> {review.wellKnown ? "Да, " + review.sourceUrl : "Нет"}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProblemPage;
